import styled from "styled-components";
//import hatchBackdrop from "./img/hatch_backdrop.jpg"; // ${hatchBackdrop}

export const StyledHatch = styled.div`
	padding-top: 100%; /* padding-top is based on the width - believe it or not!!! */
	position: relative;
	cursor: pointer;

	.front {
		/*background: center / cover url(${props => props.background});*/
		background: rgba(255,255,255, 0.3);
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		backface-visibility: hidden;
		transform-style: preserve-3d;

		p {
			display: flex;
			align-items: center;
			justify-content: center;
			font-family: "Dancing Script", cursive;
			color: #fff;
			padding: 20px;
			width: 50%;
			height: 50%;
			border-radius: 50%;
			background: rgba(0, 0, 0, 0.7);
			font-weight: 700;
			font-size: 4rem;
		}

		&.open {
			transform: rotateY(180deg);
		}
	}

	.back {
		position: absolute;
		background: center / cover url(${props => props.background});
		top: 0px;
		left: 0px;
		z-index: 1;
		transform: rotateY(180deg);
		backface-visibility: hidden;
		transform-style: preserve-3d;
		line-height: 1.1;

		p {
			font-family: "Dancing Script", cursive;
			color: #fff;
			padding: 10px;
			font-size: 1.4rem;
			text-align: center;
			background-color: rgba(0, 0, 0, 0.6);
		}

		a, a:visited {
			font-family: "Dancing Script", cursive;
			color: #fff;
			padding: 10px;
			font-size: 1.4rem;
			text-align: center;
			background-color: rgba(0, 0, 0, 0.6);
			text-decoration: none;

			&:hover {
				color: #d7d7d7;
				background-color: rgba(0, 0, 0, 0.8);
			}
		}

		&.open {
			z-index: 2;
			transform: rotateY(0deg);
			cursor: auto;
		}
	}

	> div {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		transition: all 0.9s;
		transform-style: flat;
		border-radius: 20px;
		border: 1px solid #fff;
		box-sizing: border-box;
	}

	@keyframes shake {
		0% { transform: translate(2px, 1px) rotate(0deg); } 
		10% { transform: translate(-1px, -2px) rotate(-1deg); }
		20% { transform: translate(-3px, 0px) rotate(1deg); }
		30% { transform: translate(0px, 2px) rotate(0deg); }
		40% { transform: translate(1px, -1px) rotate(1deg); }
		50% { transform: translate(-1px, 2px) rotate(-1deg); }
		60% { transform: translate(-3px, 1px) rotate(0deg); }
		70% { transform: translate(2px, 1px) rotate(-1deg); }
		80% { transform: translate(-1px, -1px) rotate(1deg); }
		90% { transform: translate(2px, 2px) rotate(0deg); }
		100% { transform: translate(1px, -2px) rotate(-1deg); }
	}

	&.shake {
		animation-name: shake;
		animation-duration: 0.5s;
		transform-origin:50% 50%;
		animation-iteration-count: infinite;
	}
`;
