import React from 'react'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'

const AboutContent = () => {
	
	return (
		<Popup
		trigger={<button className="button blue">Über diesen Adventskalender</button>}
		modal
		nested
		>
			{close => (
			<div className="modal">
				<button className="close" onClick={close}>
					&times;
				</button>
				<div className="header">Über diesen Adventskalender</div>
				<div className="content">
					<p>Liebe Musikfreunde,</p>
					<p>ein besonderes Jahr geht zu Ende. Ein Jahr, das so nie vorstellbar war und vielen von uns viel, oft auch zu viel abverlangt hat. Der Silberstreif am Horizont ist sichtbar und wir wünschen uns allen, soweit möglich, eine besinnliche Adventszeit.</p>
					<p>Unser Adventskalender ist ein kleines Geschenk an euch alle und wir hoffen, dass wir so einen kleinen Beitrag dazu leisten jeden Tag im Advent Freude zu bereiten.</p>
					<p>Wir danken unseren Mitstreitern/innen, die uns zum Teil schon seit Jahrzehnten unterstützen und ohne die wir es nicht geschafft hätten, jeden Tag im Advent mit Musik Danke zu sagen, für die Unterstützung und ihr Engagement.</p>
					<p>Wir wünschen euch heute schon frohe Weihnachten und uns allen ein gutes und gesundes neues Jahr.</p>
					<p>SÜDLICH VON STUTTGART</p>
				</div>
				<div className="actions">
					<button
						className="button"
						onClick={() => {
							close()
						}}
					>
						OK
					</button>
				</div>
			</div>
		)}
		</Popup>
	)
}

export default AboutContent