import React from 'react'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'

const AboutContent = () => {
	
	return (
		<Popup
		trigger={<button className="button blue">Impressum/Datenschutz</button>}
		modal
		nested
		>
			{close => (
			<div className="modal imprint">
				<button className="close" onClick={close}>
					&times;
				</button>
				<div className="header">Impressum</div>
				<div>
		
					<p>Christian Baumgärtner<br />Lammerbergstraße 56<br/>72461 Albstadt<br/>Mobil: 0173/8810615</p>
					<p>und<br/><br/>Patric Menger<br/>Ob Gärten 35<br/>78606 Seitingen<br/>Mobil: 0162/2346264</p>
					
					<p><strong>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</strong></p>
					<p>Christian Baumgärtner<br/>Lammerbergstraße 56<br/>72461 Albstadt</p>
					<p>Quelle: <i>erstellt mit dem <a href="http://www.e-recht24.de/impressum-generator.html" target="_blank" rel="noopener noreferrer">Impressum-Generator Einzelunternehmer</a> von eRecht24.</i></p>
					
					<p><strong>Urheberrecht</strong></p>
					<p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>

					<p><strong>Datenschutz</strong></p>
					<p>Die Nutzung dieser Webseite ist ohne Angabe personenbezogener Daten möglich. Es werden keine Cookies oder Tracking verwendet.</p>
					<p>Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.</p>
					<p>Die Zugriffe auf diese Website werden ausschließlich zu statischen Zwecken erfasst. Dabei werden keine IP Adressen oder Daten, die eine Nachverfolgung ermöglichen würden, erfasst.</p>
					<p>&nbsp;</p>
				</div>
				<div className="actions">
					<button
						className="button"
						onClick={() => {
							close()
						}}
					>
          	OK
          </button>
        </div>
      </div>
    )}
		</Popup>
	)
}

export default AboutContent