import styled from "styled-components";

export const HatchesGrid = styled.section`
	display: grid;
	grid-gap: 20px;
	grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	padding: 40px 60px;
	perspective: 3000px;
	@media (max-width: 600px) {
		grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
		padding: 40px 40px;
	}
	@media (max-width: 480px) {
		grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
		padding: 40px 20px;
	}
`;