import React, {useState, useEffect} from "react"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom"
//import { createGlobalStyle } from "styled-components"
import { HatchesGrid } from "./HatchesGrid"
import {hatchArray} from "./hatches"
import Hatch from "./Hatch"
import Snowfall from 'react-snowfall'
import {okToFlipHatch} from './utilities'
import SVS_Logo from './img/SvS_Logo_white_1200.png'
import SVS_Band from './img/SvS_Band_head.jpg'
import ContentAbout from './ContentAbout'
import ContentImprint from './ContentImprint'
import './styles.scss'
import Votes from './Votes'
//import Votes from './Votes'
import VotesOver from './Votes_over'


function CalendarPage() {
	const [hatches, setHatches] = useState([])
	const [openHatches, setOpenHatches] = useState([])
	// const [modalOpen, setModalOpen] = useState(false)
	// const [modalContent, setModalContent] = useState('')

	useEffect(() => {
		let calendar
		if (localStorage.openHatches) {
			const openHatches = JSON.parse(localStorage.openHatches)
			calendar = hatchArray.map(theHatch => {
				if (openHatches.indexOf(theHatch.id) > -1) {
					theHatch.open = true
				}
				return theHatch
			})
			setOpenHatches(openHatches)
		} else {
			calendar = hatchArray
		}
		setHatches(calendar)
	}, [])

	// Store openHatches in localStorage
	useEffect(() => {
		openHatches && localStorage.setItem("openHatches", JSON.stringify(openHatches))
	}, [openHatches])


	const handleFlipHatch = (id) => {
		if (okToFlipHatch(getHatchById(id))) {
			const updatedHatches = hatches.map(hatch => {
				if (hatch.id === id) {
					const isOpen = !hatch.open
					hatch.open = isOpen
					if (isOpen) {
						if (openHatches.indexOf(hatch.id) === -1) {
							setOpenHatches([...openHatches, id])
						}
					} else {
						const idx = openHatches.indexOf(hatch.id)
						if (idx > -1) {
							openHatches.splice(idx, 1)
							setOpenHatches([...openHatches])
						}
					}
				}
				return hatch
			})
			setHatches(updatedHatches)
		}
	}

	const getHatchById = id => {
		return hatches.find(item => item.id === id)
	}

	// const doAbout = () => {
	// 	setModalOpen(Content_About)
	// 	setModalOpen(true)
	// }

	// const doImprint = () => {
		
	// }

	// const closeModal = () => {
	// 	setModalOpen(false)
	// }

	return (
		<>
			<Snowfall />
			<div className="page-content">
			<div className="content-scroller">
			<header>
				<img src={SVS_Logo} alt="Südlich von Stuttgart Logo" />
				<img src={SVS_Band} className="img-band" alt="Südlich von Stuttgart Band" />
				<p className="lead no-margin">Wir wünschen einen musikalischen Advent</p>
			</header>
			<section className="hero">
				<h2>Christmas Special</h2>
				<p>Wähle Deinen Lieblings-Song aus dem musikalischen Adventskalender und gewinne das SÜDLICH VON STUTTGART Überraschungs-Paket!</p>
				<Link to="/vote" className="button blue">Hier geht es zur Abstimmung</Link>
			</section>
			<HatchesGrid>
				{hatches.map(hatch => (
					<Hatch
						key={hatch.id}
						hatchData={hatch}
						handleClick={handleFlipHatch}
					/>
				))}
				</HatchesGrid>
				<footer>
					<ContentAbout />
					<ContentImprint />
				</footer>
			</div>
			</div>
		</>
	)
}

function App() {
	return (
		<Router>
			<Switch>
				<Route path="/vote">
					<VotesOver />
				</Route>
				<Route path="/">
					<CalendarPage />
				</Route>
			</Switch>
		</Router>
	)
}

export default App;
