import React, {useState} from "react"
import { StyledHatch } from "./HatchStyles"
import {okToFlipHatch} from './utilities'

const linkClick = (evt) => {
  //evt.preventDefault();
  evt.stopPropagation()
};

const Hatch = ({ hatchData, handleClick }) => {
  const [doShake, setDoShake] = useState(false)

  const doHandleClick = (theId) => {
    if (okToFlipHatch(hatchData)) {
      handleClick(theId)
    } else {
      //console.log("not ok")
      setDoShake(true)
      setTimeout(() => {setDoShake(false)}, 500)
    }
  }

  return (
  <StyledHatch background={hatchData.img} onClick={() => doHandleClick(hatchData.id)} className={doShake ? "shake" : null}>
    <div className={hatchData.open ? "front open" : "front"}>
      <p>{hatchData.nr}</p>
    </div>
    <div className={hatchData.open ? "back open" : "back"}>
      {hatchData.link ?
        <a href={hatchData.link} target="_blank" rel="noreferrer noopener" onClick={linkClick}>{hatchData.text}
        </a>
        :
        <p>{hatchData.text}</p>
      }
    </div>
  </StyledHatch>
)}

export default Hatch
