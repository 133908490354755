/**
 * Found this on Stackoverflow https://stackoverflow.com/questions/6274339/how-can-i-shuffle-an-array
 * Shuffles array in place. ES6 version
 * @param {Array} a - An array containing the items.
 */
// const shuffle = a => {
// 	for (let i = a.length - 1; i > 0; i--) {
// 		const j = Math.floor(Math.random() * (i + 1));
// 		[a[i], a[j]] = [a[j], a[i]];
// 	}
// 	return a;
// };

// export const createHatches = () => {
//   const hatchArray = new Array(24).fill(0).map((_, i) => ({
//     id: `hatch-${i}`,
//     nr: i + 1,
//     img: `./img/${i + 1}.jpg`,
//     text: `Just ${24 - i - 1} days left`,
//     open: false
//   }));
//   return shuffle(hatchArray);
// };

export const hatchArray = [
	{
		id: 'hatch-20',
		nr: 20,
		img: './img/20.jpg',
		text: 'J.S. Bach Fantasia in C | BWV 573',
		link: 'https://youtu.be/QYGxWQQOt-M',
		open: false
	},
	{
		id: 'hatch-3',
		nr: 3,
		img: './img/3.jpg',
		text: 'Somewhere over the rainbow',
		link: 'https://youtu.be/aZYs4XH-BPk',
		open: false
	},
	{
		id: 'hatch-17',
		nr: 17,
		img: './img/17.jpg',
		text: '50 ways to leave your lover',
		link: 'https://youtu.be/HSyJ6CmnBUY',
		open: false
	},
	{
		id: 'hatch-1',
		nr: 1,
		img: './img/1.jpg',
		text: 'Harvest for the world',
		link: 'https://youtu.be/aY-24XNkKlY',
		open: false
	},
	{
		id: 'hatch-18',
		nr: 18,
		img: './img/18.jpg',
		text: 'Have yourself a merry little christmas',
		link: 'https://youtu.be/5feJHEoSzps',
		open: false
	},
	{
		id: 'hatch-9',
		nr: 9,
		img: './img/9.jpg',
		text: 'My ship',
		link: 'https://youtu.be/y34Jnr_EThA',
		open: false
	},
	{
		id: 'hatch-21',
		nr: 21,
		img: './img/21.jpg',
		text: 'Angels we have heard on high',
		link: 'https://youtu.be/3HbcIXpp-sA',
		open: false
	},
	{
		id: 'hatch-22',
		nr: 22,
		img: './img/22.jpg',
		text: 'Dein ist mein ganzes Herz',
		link: 'https://youtu.be/QlfGWuhNJis',
		open: false
	},
	{
		id: 'hatch-23',
		nr: 23,
		img: './img/23.jpg',
		text: 'Driving home for Christmas',
		link: 'https://youtu.be/2AaNttED850',
		open: false
	},
	{
		id: 'hatch-6',
		nr: 6,
		img: './img/6.jpg',
		text: 'Nun komm, der Heiden Heiland | BWV 659',
		link: 'https://youtu.be/OnlV0Sg7-hU',
		open: false
	},
	{
		id: 'hatch-19',
		nr: 19,
		img: './img/19.jpg',
		text: 'Lovely day',
		link: 'https://youtu.be/kqCAPKJeVeg',
		open: false
	},
	{
		id: 'hatch-15',
		nr: 15,
		img: './img/15.jpg',
		text: 'To know him is to love him',
		link: 'https://youtu.be/q87B2UwSemI',
		open: false
	},
	{
		id: 'hatch-10',
		nr: 10,
		img: './img/10.jpg',
		text: 'I can’t go for that',
		link: 'https://youtu.be/2g7_COq35ZM',
		open: false
	},
	{
		id: 'hatch-24',
		nr: 24,
		img: './img/24.jpg',
		text: 'Stille Nacht',
		link: 'https://youtu.be/YGjgBfFp9-E',
		open: false
	},
	{
		id: 'hatch-5',
		nr: 5,
		img: './img/5.jpg',
		text: 'Rudolph the red-nosed reindeer',
		link: 'https://youtu.be/VE07l-t6bJY',
		open: false
	},
	{
		id: 'hatch-8',
		nr: 8,
		img: './img/8.jpg',
		text: 'Imagine',
		link: 'https://youtu.be/cra0ruWzta8',
		open: false
	},
	{
		id: 'hatch-7',
		nr: 7,
		img: './img/7.jpg',
		text: 'Human nature',
		link: 'https://youtu.be/HIzylh7esW0',
		open: false
	},
	{
		id: 'hatch-13',
		nr: 13,
		img: './img/13.jpg',
		text: 'In der Weihnachtsbäckerei',
		link: 'https://youtu.be/cGG4cnN0G0A',
		open: false
	},
	{
		id: 'hatch-16',
		nr: 16,
		img: './img/16.jpg',
		text: 'Hallelujah',
		link: 'https://youtu.be/6LmFPDm1jqs',
		open: false
	},
	{
		id: 'hatch-12',
		nr: 12,
		img: './img/12.jpg',
		text: 'Santa Claus is coming to town',
		link: 'https://youtu.be/QYscD24Kpj8',
		open: false
	},
	{
		id: 'hatch-14',
		nr: 14,
		img: './img/14.jpg',
		text: 'Your song',
		link: 'https://youtu.be/xTx-JhYtgAg',
		open: false
	},
	{
		id: 'hatch-2',
		nr: 2,
		img: './img/2.jpg',
		text: 'Fields of gold',
		link: 'https://youtu.be/COCNjQdpu48',
		open: false
	},
	{
		id: 'hatch-11',
		nr: 11,
		img: './img/11.jpg',
		text: 'Con los años que me quedan',
		link: 'https://youtu.be/brM9Ju9Q0Yw',
		open: false
	},
	{
		id: 'hatch-4',
		nr: 4,
		img: './img/4.jpg',
		text: 'Always look on the bright side of life',
		link: 'https://youtu.be/EHt7MzxrBwc',
		open: false
	}
]
