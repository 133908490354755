import React from "react"
import {Link} from "react-router-dom"
import SVS_Logo from './img/SvS_Logo_white_1200.png'
import SVS_Band from './img/SvS_Band_head.jpg'
import './styles.scss'


function VotesOver() {

	return (
		<>
			<div className="page-content">
			<div className="content-scroller">
			<header>
				<img src={SVS_Logo} alt="Südlich von Stuttgart Logo" />
				<img src={SVS_Band} className="img-band" alt="Südlich von Stuttgart Band" />
		
			</header>
			<div className="page-margin">
				<section className="hero">
					<h2>Musikalischer Adventskalender Charts</h2>
					<p>Die Wahl für eueren persönlichen Favoriten ist abgeschlossen.<br/> Der von den meisten favorisierte Titel ist „Driving home for Christmas“.</p>
					<p>Die glückliche Gewinnerin des Südlich von Stuttgart Überraschungspakets ist <nobr>Elvira Wohnhas.</nobr></p>
					<p>Vielen Dank für euer großes Interesse bei der Abstimmung. Wir haben uns ganz besonders über die vielen Kommentare gefreut, in denen ihr euer Gefallen am Adventskalender zum Ausdruck gebracht habt. Wie der Aplaus des Künstlers Brot ist, sind euere netten Rückmeldungen in Zeiten des Social Distancing unser Brot. Das bedeutet uns sehr viel. Herzlichen Dank an alle.</p>
				
				<Link to="/" className="button blue" style={{marginBottom: "1em", marginTop: "2em"}}>Zurück zum Adventskalender</Link>
				</section>
			</div>
		
			</div>
			</div>
		</>
	)
}



export default VotesOver
